<template>
    <div class="catalog-family-search-filters-container">
        <cr-text-field v-model="searchText" :label="t('EXERCISE_GROUP_CREATE_SEARCH_CATALOG_FAMILIES_LABEL')" @keyup.enter="onSearchTextEnterKeyUp" data-testing="catalog-family-search-text-field"/>

        <fieldset v-for="values,key of searchFields" class="cr-mt3 search-field-group" data-testing="catalog-family-search-field-group">
            <legend class="search-field-group-label">{{ searchFieldKeys[key] }}</legend>
            <div class="search-field-group-buttons">
                <cr-toggle class="cr-ml2 cr-mr2 cr-mb2" v-for="name of Object.keys(values)" v-model="searchFields[key][name]" :tooltip="searchFieldData[key][name].tooltip" :data-testing="`catalog-family-search-${key}-${name}`.toLowerCase()" >{{ searchFieldData[key][name].displayName }}</cr-toggle>
            </div>
        </fieldset>
    </div>
</template>

<style scoped>
.catalog-family-search-filters-container {
    container-name: catalog-family-search-filters;
    container-type: inline-size;
}
.search-field-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.search-field-group-label {
    width: auto;
    font-size: var(--cr-sectionTitleFontSize);
}
.search-field-group-buttons {
    display: flex;
    flex-wrap: wrap;
}
/* 54rem based on width of labels and filters used. Check and potentially update anytime labels and filters are changed */
@container catalog-family-search-filters (max-width: 54rem) {
    .search-field-group-label {
        width: 100%;
    }
}
</style>

<script lang="ts">
const searchFieldKeys = {
    environmentTypes: "Environment Types",
    operatingSystems: "Operating Systems",
    networks: "Networks",
    coursewarePartners: "Courseware Partner Environments",
} as const;

const searchFieldData: Record<keyof typeof searchFieldKeys, Record<string,{ displayName: string, tooltip?: string }>> = {
    environmentTypes: {
        snapshot: {
            displayName: "My Saved Environments",
        },
        single_vm_environment: {
            displayName: "Single VM Environments",
        },
        multi_vm_environment: {
            displayName: "Multi VM Environments",
        },
        ctf: {
            displayName: "Capture-the-flag",
        },
    },
    operatingSystems: {
        kali: {
            displayName: 'Kali',
        },
        windows: {
            displayName: 'Windows',
        },
        centOS: {
            displayName: 'CentOS',
        },
        debian: {
            displayName: 'Debian',
        },
        ubuntu: {
            displayName: 'Ubuntu',
        },
    },
    networks: {
        isolated: {
            displayName: "Isolated Networks",
            tooltip: "In isolated networks, there is never connectivity between classroom exercise environments.",
        },
        shared: {
            displayName: "Shared Networks",
            tooltip: 'In shared networks, the "Inter-Team Connectivity" feature allows connectivity between classroom exercise environments to be toggled on and off as needed.',
        }
    },
    coursewarePartners: {
        teach_cyber: {
            displayName: 'Teach Cyber',
        },
        seed_labs: {
            displayName: 'SEED Labs',
        },
    },
} as const;
</script>

<script setup lang="ts">
import { CatalogFamilySearchFilter, ICatalogFamily, ICatalogFamilySearchFilter } from '@cyber-range/cyber-range-api-catalog-client';
import { onBeforeMount, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCatalogStore } from '../../stores/catalogStore';
import { CatalogFamilyMetadataTag } from '@cyber-range/cyber-range-api-catalog-client';

const props = defineProps<
{
    organizationIds: string[];
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: ICatalogFamily[]): void
    (e: 'loading', value: boolean): void
}>()

const { t } = useI18n();

const searchFields: Record<keyof typeof searchFieldKeys, Record<string,boolean>> = reactive({
    environmentTypes: {
        snapshot: false,
        single_vm_environment: false,
        multi_vm_environment: false,
        ctf: false,
    },
    operatingSystems: {
        kali: false,
        windows: false,
        centOS: false,
        debian: false,
        ubuntu: false,
    },
    networks:{
        isolated: false,
        shared: false
    },
    coursewarePartners: {
        teach_cyber: false,
        seed_labs: false,
    },
});

const searchText = ref('');

watch(()=>({...searchFields, organizationIds:props.organizationIds}), doSearch, { deep: true })

onBeforeMount(() =>
{
    if (history.state?.filter)
    {
        const filter = new CatalogFamilySearchFilter(JSON.parse(history.state.filter));

        if (filter.query)
        {
            searchText.value = filter.query;
        }

        for (const key of Object.keys(searchFields))
        {
            if (!(key in filter)) continue;

            for (const value of filter[key as keyof ICatalogFamilySearchFilter] as string | string[])
            {
                if (searchFields[key as keyof typeof searchFields]?.[value] === undefined) continue;

                searchFields[key as keyof typeof searchFields][value] = true;
            }
        }
    }
});

let cancelSearch: Function|undefined;
const isLoading = ref(0)
async function doSearch()
{
    cancelSearch?.();
    let shouldStop = false;
    cancelSearch = () => { shouldStop = true };

    isLoading.value++;
    const environmentTypes = Object.entries(searchFields.environmentTypes).filter(([k,v]) => v).map(([k,v]) => k);
    const operatingSystems = Object.entries(searchFields.operatingSystems).filter(([k,v]) => v).map(([k,v]) => k);
    const networks = Object.entries(searchFields.networks).filter(([k,v]) => v).map(([k,v]) => k);
    const coursewarePartners = Object.entries(searchFields.coursewarePartners).filter(([k,v]) => v).map(([k,v]) => k);

    const useFilter = searchText.value || environmentTypes.length || operatingSystems.length || networks.length || coursewarePartners.length;

    const filter: ICatalogFamilySearchFilter|undefined = useFilter
        ? new CatalogFamilySearchFilter({ query: searchText.value, environmentTypes, operatingSystems, networks, coursewarePartners, organizationIds: props.organizationIds })
        : undefined;
    let families = await useCatalogStore().listCatalogFamilies(filter);
    // Filter out snapshots when the snapshot filter is not selected
    if (!searchFields.environmentTypes.snapshot)
    {
        families = families.filter(family => !family.metadata?.tags?.includes(CatalogFamilyMetadataTag.EnvironmentTypesSnapshot));
    }

    if (shouldStop)
    {
        isLoading.value--;
        return;
    }
    cancelSearch = undefined;

    emit('update:modelValue', families);
    history.replaceState({ ...history.state, filter: JSON.stringify(filter) }, '');
    isLoading.value--;
}
const onSearchTextEnterKeyUp = doSearch

watch(isLoading, (newValue, oldValue) =>
{
    if (oldValue == 0)
    {
        emit('loading', true)
    }
    else if (newValue === 0)
    {
        emit('loading', false);
    }
});

</script>
