<template>
    <cr-table :items="page?.items" :headers="headers" :loading="isLoading" @sort="onSort" class="cr-mb5" data-testing="exercise-group-exercises-table">
        <template v-slot:ownerName="{value, item}">
            <div data-testing="exercise-group-exercises-table-team-name" :class="nameCellClass" >{{ value }}</div>
            <div v-if="!!getExerciseSubdomain(item)" :class="`cr-caption-darken-1 ${nameCellClass}`" data-testing="exercise-group-exercises-table-team-subdomain">
                {{ getExerciseSubdomain(item) }}
            </div>
        </template>
        <template v-slot:controls="{item}">
            <exercise-group-exercise-controls compact :model-value="item" :loading="loadingExercisesMap.get(item.id)" @update:loading="onExerciseLoading(item.id, $event)"/>
        </template>
        <template v-slot:status="{value, item}">
            <div :class="statusCellClass" data-testing="exercise-group-exercises-table-status">
                <exercise-status-chip :status="value" :loading="loadingExercisesMap.get(item.id)" />
            </div>
        </template>
        <template v-slot:statistics="{item}">
            <div class="always-align-center">
                <template v-if="showExerciseDetails(item)">
                    <expandable-exercise-activity-cell :activity="getActivity(item)" :exercise="item"/>
                </template>
                <template v-else>
                    <cr-warning-text v-if="showResetExerciseWarning(item)" :message="t('EXERCISE_GROUP_EXERCISE_NEEDS_A_REINSTALL_MESSAGE')" :data-testing="`exercise-${item.id}-reset-warning-text`"/>
                    <div>
                        {{getActivity(item)}}
                    </div>
                    <exercise-low-duration-warning :exercise="item"/>
                </template>
            </div>
        </template>
    </cr-table>

    <cr-pagination :previous="!!page?.prevPageToken" :first="true" :next="!!page?.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" :loading="isLoading"/>
</template>

<style scoped>
:deep(.cr-table-cell):not(:has(.always-align-center))
{    
    vertical-align: top;
}
:deep(th:last-child) 
{
    width: 50%; /* reduce jumpyness of the activity cell on activity changes and system info expansion */
}
</style>

<script setup lang="ts">
import { IApiPageResponse, SortOrder } from "@cyber-range/cyber-range-api-client";
import { DnsRecordName, ExerciseGroupExercisesFilter, ExerciseGroupExercisesSortBy, ExerciseStatus, IExercise, IExerciseStatistics } from "@cyber-range/cyber-range-api-exercise-client";
import { ITableHeaderItem, TableHeaderItem, useAnnouncementStore } from "@cyber-range/cyber-range-lib-ui";
import { onBeforeUnmount, computed, reactive, ref, toRaw, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useCalendar } from '../../composables/useCalendar';
import { useAuthorizationStore } from "../../stores/authorizationStore";
import { useExerciseStore } from '../../stores/exerciseStore';
import { useTimerStore } from "../../stores/timerStore";
import ExerciseLowDurationWarning from './ExerciseLowDurationWarning.vue'
import ExerciseGroupExerciseControls from "./ExerciseGroupExerciseControls.vue";
import ExerciseStatusChip from './ExerciseStatusChip.vue';
import Config from "../../config";
import ExpandableExerciseActivityCell from './ExpandableExerciseActivityCell.vue';

const { toHumanDate, toHuman, toHumanDuration } = useCalendar();

const props = defineProps<
{
    exerciseGroupId: string;
}>();
const { t } = useI18n();
const exerciseStore = useExerciseStore();
const timerStore = useTimerStore();

const headers = [
    new TableHeaderItem({ text:t('EXERCISE_TEAM_NAME'), key: 'ownerName', sortable: true }),
    new TableHeaderItem({ text:'', key: 'controls' }),
    new TableHeaderItem({ text:t('EXERCISE_STATUS'), key: 'status', align: 'center' }),
    new TableHeaderItem({ text:t('EXERCISE_ACTIVITY'), key: 'statistics', formatter: getActivity }),
];

const getExerciseSubdomain = (exercise: IExercise) => exercise.dnsRecords?.find(r => r.name === DnsRecordName.Subdomain)?.value;

const loadingExercisesMap = reactive(new Map<string, boolean>());
function onExerciseLoading(id: string, isLoading: boolean)
{
    loadingExercisesMap.set(id, isLoading);
}

const showResetExerciseWarning = (exercise:IExercise):boolean =>
{
    return exercise.status === ExerciseStatus.ERROR;
}

const setCurrentPageExercisesLoading = (exerciseIds?: string[]) =>
{
    let currentPageIds = page.value?.items.map(ex => ex.id) || [];

    if (exerciseIds)
    {
        currentPageIds = currentPageIds.filter(id => exerciseIds.includes(id));
    }

    currentPageIds.forEach(id => loadingExercisesMap.set(id, true));
};

function getActivity(data: IExercise)
{
    if(data?.statistics)
    {
        if (data.statistics.activeSessions > 0 && data.status === ExerciseStatus.READY)
        {
            const timestamp = toHuman(data.statistics.lastAccessTimestamp);
            return t("EXERCISE_IN_PROGRESS",{ timestamp });
        } 
        if (data.statistics.firstAccessTimestamp && data.statistics.lastAccessTimestamp)
        {
            const firstAccessDate = toHumanDate(data.statistics.firstAccessTimestamp);
            const lastAccessDate = toHumanDate(data.statistics.lastAccessTimestamp);
            const duration = toHumanDuration(data.statistics.totalAccessDurationInMs) || '0 minutes';

            if (firstAccessDate === lastAccessDate)
            {
                return t("EXERCISE_LAST_ACCESSED",{
                    duration,
                    date: firstAccessDate
                });
            }
            else
            {
                return t("EXERCISE_LAST_ACCESSED_BETWEEN", {
                    duration,
                    from: firstAccessDate,
                    to: lastAccessDate
                });
            }
        }
    }
    return '';
}

const page = ref<IApiPageResponse<IExercise>>();
const filter = reactive(new ExerciseGroupExercisesFilter({limit: Config.EXERCISE_TABLE_PAGE_SIZE}));
const isLoading = ref(false);

let timerId: string;
async function reload()
{
    if (page.value === undefined)
    {
        isLoading.value = true;
    }

    timerStore.unschedule(timerId);

    page.value = await exerciseStore.getExerciseGroupExercises(props.exerciseGroupId, toRaw(filter), { background: true });

    timerId = timerStore.schedule(reload, undefined, 5000, false);

    isLoading.value = false;
}
watch(filter, reload, { immediate: true });

const announcementStore = useAnnouncementStore();
let exerciseStatusMap = new Map<string,ExerciseStatus>();
watch(() => page.value?.items, () =>
{
    const statusCountMap = new Map<ExerciseStatus, number>();
    const newExerciseStatusMap = new Map<string, ExerciseStatus>()

    for (const item of page.value?.items || [])
    {
        newExerciseStatusMap.set(item.id, item.status)

        if (exerciseStatusMap.has(item.id) && exerciseStatusMap.get(item.id) !== item.status)
        {
            const count = statusCountMap.get(item.status) || 0
            statusCountMap.set(item.status, count + 1);
        }
    }

    exerciseStatusMap = newExerciseStatusMap;

    const announcements = []
    for (const [status, count] of statusCountMap)
    {
        announcements.push([
            count,
            count > 1 ? 'exercises' : 'exercise',
            status.replace('pending_', '')
        ].join(' '));
    }
    if (announcements.length > 0)
    {
        announcementStore.announce(announcements.join('. '));
    }
}, { immediate: true, deep: true });

async function onSort (header:ITableHeaderItem)
{
    filter.sortOrder = header.sortOrder as SortOrder
    filter.sortBy = (header.key === 'statistics')
        ? ExerciseGroupExercisesSortBy.TotalAccessDuration
        : header.key?.toLowerCase() as ExerciseGroupExercisesSortBy;
    filter.token = '';
}

function onLoadFirstPage()
{
    filter.token = '';
}

function onLoadPreviousPage()
{
    filter.token = page.value!.prevPageToken;
}

function onLoadNextPage()
{
    filter.token = page.value!.nextPageToken;
}

onBeforeUnmount(() =>
{
    timerStore.unschedule(timerId);
});

defineExpose({setCurrentPageExercisesLoading});

// adjustments when an expandable cell exists
const { canViewSystemInfo } = useAuthorizationStore();

let tableHasExpansionCell = ref<boolean>(false);
let tableHasSubdomain = ref<boolean>();

const nameCellClass = computed(()=>{
    return !tableHasExpansionCell.value
        ? "always-align-center"
        :  !tableHasSubdomain.value
            ? "cr-pt2"
            : ""
})

const statusCellClass = computed(()=>{
    return !tableHasExpansionCell.value
        ? "always-align-center"
        : "cr-pt2 cr-pb1"
})

const showExerciseDetails = (exercise:IExercise) =>
{
    let result = canViewSystemInfo(exercise.organizationId);
    if(tableHasSubdomain.value === undefined)
    {
        tableHasSubdomain.value = !!getExerciseSubdomain(exercise);
    }
    if(result === true)
    {
        tableHasExpansionCell.value = true;
    }
    return result;
}
</script>
