import { createRouter, createWebHistory, isNavigationFailure, NavigationFailureType } from 'vue-router';
import Route from './route';

declare module 'vue-router' {
    interface RouteMeta {
        title?: RouteRecordNameGeneric;
        navigationId?: number;
    }
}

let router = createRouter(
{
    history: createWebHistory(),
    routes: Route.toRouteRecordRaw()
});

export const forceReloadSymbol = Symbol('Force Route Reload');

router.afterEach((to, from, failure) =>
{
    if (isNavigationFailure(failure, NavigationFailureType.duplicated))
    {
        router.push({ ...to, force:true, replace:true });
    }
});
router.beforeEach((to, from, next) =>
{
    if (to.fullPath === from.fullPath) {
        to.meta.navigationId = from.meta.navigationId ?? 0;
        to.meta.navigationId += 1;
    }
    return next();
});

export default router