<template>
    <read-layout :title="organization?.name" :breadcrumbs="breadcrumbs" :ready="isReady">

        <template #top>
            <cr-row>
                <cr-col xs12 sm12 md12 lg12 xl4>
                    <cr-image :src="organizationLogo" :alt="organization!.name" class="cr-p4" min-height="10rem" max-height="18rem" width="100%" cover="true" data-testing="organization-logo" />
                </cr-col>
                
                <cr-col xs12 sm12 md12 lg12 xl8>
                    <read-section :label="t('ORGANIZATION_DESCRIPTION')" data-testing="organization-description">
                        <p>{{ organization?.description }}</p>
                    </read-section>
                    <read-section v-if="canViewOrganizationContact" :label="t('ORGANIZATION_CONTACT')" class="cr-mt5" data-testing="organization-contact">
                        <cr-row data-testing="organization-contact-name">
                            <cr-col>
                                {{ organization?.contact?.name }}
                            </cr-col>
                        </cr-row>
                        <cr-row v-if=organization?.contact data-testing="organization-contact-address-line-1">
                            <cr-col>
                                {{ organization.contact.addressLine1 }}
                            </cr-col>
                        </cr-row>
                        <cr-row v-if=organization?.contact?.addressLine2 data-testing="organization-contact-address-line-2">
                            <cr-col>
                                {{ organization.contact.addressLine2 }}
                            </cr-col>
                        </cr-row>
                        <cr-row v-if=organization?.contact data-testing="organization-contact-address-city-state-zip">
                            <cr-col>
                                {{ cityStateZip }}
                            </cr-col>
                        </cr-row>
                        <cr-row data-testing="organization-contact-email">
                            <cr-col>
                                <cr-icon :alt="t('ORGANIZATION_CONTACT_EMAIL_ADDRESS')" class="cr-mr1">bi-envelope-at</cr-icon>
                                <a class="link" :href="contactEmailMailtoAddress">
                                    {{ organization?.contact?.email }}
                                </a>
                            </cr-col>
                        </cr-row>
                        <cr-row data-testing="organization-contact-phone">
                            <cr-col>
                                <cr-icon :alt="t('ORGANIZATION_CONTACT_PHONE_NUMBER')" class="cr-mr1">bi-telephone-fill</cr-icon>{{ organization?.contact?.phone }}
                            </cr-col>
                        </cr-row>
                    </read-section>
                    <read-detail-section v-if="showAdvancedSettings" class="cr-mt5" :label="t('ORGANIZATION_ADVANCED_SETTINGS_SECTION_LABEL')"  data-testing="organization-advanced-settings-section">
                        <read-detail-section-item v-if="organization?.parentName" :label="t('ORGANIZATION_ADVANCED_SETTINGS_PARENT_ORGANIZATION')" :wide="useWide" data-testing="organization-advanced-settings-parent-organization">
                            <router-link v-if="canManageOrganization(organization?.parentId)" :to="parentOrganizationRoute" class="link" data-testing="organization-advanced-settings-parent-organization-link"> {{ organization?.parentName }} </router-link>
                            <template v-else> {{ organization?.parentName }} </template>
                        </read-detail-section-item>
                        <read-detail-section-item v-if="showAlternateIds" :label="t('ORGANIZATION_ADVANCED_SETTINGS_ALTERNATE_IDS')" :wide="useWide" data-testing="organization-advanced-settings-alternate-ids" >
                            {{ organization?.alternateIds.join(", ") }}
                        </read-detail-section-item>
                        <read-detail-section-item v-if="organization?.code" :label="t('ORGANIZATION_ADVANCED_SETTINGS_ORGANIZATION_CODE')":wide="useWide" data-testing="organization-advanced-settings-organization-code" >
                            <cr-selectable-text :value="organization?.code" />
                        </read-detail-section-item>
                        <template v-if="canManageOrganizationCustomAttributes(organizationId)">
                            <read-detail-section-item v-for="attribute of displayCustomAttributes" :label="attribute.label" :wide="useWide" :data-testing="`organization-advanced-settings-${attribute.label}`" >
                                {{ attribute.value }}
                            </read-detail-section-item>
                            <read-detail-section-item v-for="attribute of displayInternalCustomAttributes" :label="attribute.label" :wide="useWide" :data-testing="`organization-advanced-settings-${attribute.label}`" >
                                {{ attribute.value }}
                            </read-detail-section-item>
                        </template>
                    </read-detail-section>
                </cr-col>
            </cr-row>
        </template>

        <template #controls>
            <read-control-item v-if="canEditOrganization(organizationId)">
                <cr-button :to="Route.EditOrganization" :disabled="isLoading" outlined data-testing="edit-organization-button">
                    <cr-icon>bi-pencil</cr-icon>
                    {{ t('ORGANIZATIONS_ORGANIZATION_EDIT_ORGANIZATION_BUTTON') }}
                </cr-button>
            </read-control-item>
            <read-control-item v-if="canViewOrganizationUsers(organizationId)">
                <cr-button :to="Route.OrganizationUsers" :disabled="isLoading" outlined data-testing="organization-users-button">
                    <cr-icon>bi-people</cr-icon>
                    {{ t('ORGANIZATIONS_ORGANIZATION_ORGANIZATION_USERS_BUTTON') }}
                </cr-button>
            </read-control-item>
            <read-control-item v-if="canViewBilling(organizationId)">
                <cr-button :to="Route.Billing" :disabled="isLoading" outlined data-testing="organization-billing-button">
                    <cr-icon>bi-currency-dollar</cr-icon>
                    {{ t('ORGANIZATIONS_ORGANIZATION_BILLING_BUTTON') }}
                </cr-button>
            </read-control-item>
            <read-control-item v-if="canListOrganizationApplications(organizationId)">
                <cr-button :to="Route.OrganizationApplications" :disabled="isLoading" outlined data-testing="organization-applications-button">
                    <cr-icon>bi-grid-3x3-gap-fill</cr-icon>
                    {{ t('ORGANIZATIONS_ORGANIZATION_ORGANIZATION_APPLICATIONS_BUTTON') }}
                </cr-button>
            </read-control-item>
            <read-control-item v-if="canManageBusinessUnits()">
                <cr-button :to="Route.BusinessUnits" :disabled="isLoading" outlined data-testing="organization-business-units-button">
                    <cr-icon>bi-bank2</cr-icon>
                    {{ t('ORGANIZATIONS_ORGANIZATION_MANAGE_BUSINESS_UNITS_BUTTON') }}
                </cr-button>
            </read-control-item>
            <read-control-item v-if="canViewLogs">
                <cr-button :to="{ name: Route.Logs.name, query: { organizationId }}" :disabled="isLoading" outlined data-testing="organization-view-logs-button">
                    <cr-icon>bi-journal-text</cr-icon>
                    {{ t('ORGANIZATIONS_ORGANIZATION_VIEW_LOGS_BUTTON') }}
                </cr-button>
            </read-control-item>
            <read-control-item v-if="canDeleteOrganization(organizationId)">
                <cr-button dialog="#deleteOrganizationDialog" :disabled="isLoading" outlined data-testing="delete-organization-button">
                    <cr-icon>bi-trash3</cr-icon>
                    {{ t('ORGANIZATIONS_ORGANIZATION_DELETE_ORGANIZATION_BUTTON') }}
                </cr-button>
            </read-control-item>
            <delete-organization-dialog :organization="organization" @confirm="onDeleteButtonClicked"/>
        </template>

        <template #bottom>
            <template v-if="canManageOrganization(props.organizationId)">
                <dashboard-section :label="t('ORGANIZATIONS_ORGANIZATION_DASHBOARD_COURSES_AND_ENVIRONMENTS_SECTION')">
                        <dashboard-section-item :large="hasCourses" data-testing="organization-dashboard-courses-section-item">
                            <dashboard-section-item-gauge-tile :metric-title="t('ORGANIZATIONS_ORGANIZATION_DASHBOARD_COURSES')" :icon="'bi-journals'" :value="organization?.statistics?.numberOfCourses" :limit="organization?.limits?.maxCoursesPerOrganization" :to="coursesRoute" data-testing="organization-dashboard-courses-tile">
                                <organization-dashboard-top-courses v-if="hasCourses" :top-courses="topCourses || []"/>
                            </dashboard-section-item-gauge-tile>
                        </dashboard-section-item>
                        <dashboard-section-item :medium="hasCourses" data-testing="organization-dashboard-environments-section-item">
                            <dashboard-section-item-gauge-tile :metric-title="t('ORGANIZATIONS_ORGANIZATION_DASHBOARD_DEPLOYED_ENVIRONMENTS')" :icon="'bi-display'" :value="organization?.statistics?.numberOfExercises" data-testing="organization-dashboard-environments-tile"/>
                        </dashboard-section-item>
                        <dashboard-section-item :medium="hasCourses" data-testing="organization-dashboard-copied-environments-section-item">
                            <dashboard-section-item-gauge-tile :metric-title="t('ORGANIZATIONS_ORGANIZATION_DASHBOARD_COPIED_ENVIRONMENTS')" icon="bi-copy" :value="organization?.statistics?.numberOfCopiedEnvironments" :limit="organization?.limits?.maxCopiedEnvironmentsPerOrganization" data-testing="organization-dashboard-copied-environments-tile"/>
                        </dashboard-section-item>
                </dashboard-section>
                <dashboard-section :label="t('ORGANIZATIONS_ORGANIZATION_DASHBOARD_ENROLLMENTS_SECTION')">
                        <dashboard-section-item>
                            <dashboard-section-item-gauge-tile :metric-title="t('ORGANIZATIONS_ORGANIZATION_DASHBOARD_TOTAL_ENROLLMENTS')" :icon="'bi-people-fill'" :value="organization?.statistics?.numberOfEnrollments" :limit="organization?.limits?.maxEnrollmentsPerOrganization" data-testing="organization-dashboard-enrollments-tile"/>
                        </dashboard-section-item>
                        <dashboard-section-item>
                            <dashboard-section-item-gauge-tile :metric-title="t('ORGANIZATIONS_ORGANIZATION_DASHBOARD_INSTRUCTORS')" :icon="'bi-mortarboard'" :value="organization?.statistics?.numberOfInstructorEnrollments" :limit="organization?.limits?.maxInstructorEnrollmentsPerOrganization" :to="instructorsRoute" data-testing="organization-dashboard-instructors-tile"/>
                        </dashboard-section-item>
                        <dashboard-section-item>
                            <dashboard-section-item-gauge-tile :metric-title="t('ORGANIZATIONS_ORGANIZATION_DASHBOARD_TAS')" :icon="'bi-person-video3'" :value="organization?.statistics?.numberOfTaEnrollments" :to="tasRoute" data-testing="organization-dashboard-tas-tile"/>
                        </dashboard-section-item>
                        <dashboard-section-item>
                            <dashboard-section-item-gauge-tile :metric-title="t('ORGANIZATIONS_ORGANIZATION_DASHBOARD_STUDENTS')" :icon="'bi-person-fill'" :value="organization?.statistics?.numberOfStudentEnrollments" :to="studentsRoute" data-testing="organization-dashboard-students-tile"/>
                        </dashboard-section-item>                                 
                </dashboard-section>
                <dashboard-section :label="t('ORGANIZATIONS_ORGANIZATION_DASHBOARD_ADMIN_ACCOUNTS_SECTION')">
                        <dashboard-section-item>
                            <dashboard-section-item-gauge-tile :metric-title="t('ORGANIZATIONS_ORGANIZATION_DASHBOARD_ADMIN_ACCOUNTS')" :icon="'bi-person-fill-gear'" :value="organization?.statistics?.numberOfAdmins" :limit="organization?.limits?.maxAdminsPerOrganization" data-testing="organization-dashboard-admins-tile"/>
                        </dashboard-section-item>
                </dashboard-section>
            </template>
            <div v-else class="cr-text-center">
                {{ t('ORGANIZATIONS_ORGANIZATION_NO_DATA_AVAILABLE') }}
            </div>
        </template>
    </read-layout>
</template>

<style scoped>
.link
{
    color: var(--cr-primary-link);
    text-decoration: underline !important;
}

</style>

<script setup lang="ts">
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Route from '../../routers/route';
import ReadLayout from '../layouts/ReadLayout.vue';
import ReadSection from '../layouts/sections/ReadSection.vue';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { IOrganization, UserRole } from '@cyber-range/cyber-range-api-organization-client';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useCourseStore } from '../../stores/courseStore';
import DashboardSection from '../layouts/sections/DashboardSection.vue';
import DashboardSectionItem from '../layouts/sections/DashboardSectionItem.vue';
import OrganizationDashboardTopCourses from './OrganizationDashboardTopCourses.vue';
import DashboardSectionItemGaugeTile from '../layouts/sections/DashboardSectionItemGaugeTile.vue';
import { CourseFilter, CourseSortBy, ICourse, OrganizationUserSortBy } from '@cyber-range/cyber-range-api-user-client';
import { SortOrder } from '@cyber-range/cyber-range-api-client';
import DeleteOrganizationDialog from './dialogs/DeleteOrganizationDialog.vue';
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';
import ReadDetailSection from '../layouts/sections/ReadDetailSection.vue';
import ReadDetailSectionItem from '../layouts/sections/ReadDetailSectionItem.vue';
import { useOrganizationCustomAttributes } from '../../composables/useOrganizationCustomAttributes';

const props = defineProps<
{
    organizationId:string;
}>();

const { t } = useI18n();
const authorizationStore = useAuthorizationStore();
const { canEditOrganization, canViewOrganizationUsers, canViewBilling, canListOrganizationApplications, canManageBusinessUnits, canDeleteOrganization, canListCourses, canManageOrganization, canEditOrganizationAlternateId, canManageOrganizationCustomAttributes } = authorizationStore;
const canViewLogs = computed(() => authorizationStore.canViewAuditEvents(props.organizationId));
const canViewOrganizationContact = computed(() => useAuthorizationStore().canViewOrganizationContact(props.organizationId));
const { isLoading } = storeToRefs(useApiClientStore());
const router = useRouter();

const organization = ref<IOrganization>();
const organizationLogo = computed(() => organization.value?.logo || useOrganizationStore().defaultLogo);
const isReady = ref(false);
const topCourses = ref<ICourse[]>([]);
const topCoursesFilter = new CourseFilter({ organizationIds: [props.organizationId], sortBy: CourseSortBy.NumberOfExercises, sortOrder: SortOrder.Desc, limit: 3 })
const breadcrumbs = computed(()=>[
                            new BreadcrumbItem(Route.Organizations),
                            new BreadcrumbItem({...Route.Organization, text: organization.value?.name, params: {organizationId: props.organizationId}})]);

const showAdvancedSettings = computed(()=>
{
    return !!(organization.value?.parentId || showAlternateIds.value || organization.value?.code || (canManageOrganizationCustomAttributes(props.organizationId) && (displayCustomAttributes.value.length || displayInternalCustomAttributes.value.length )))
})

const USE_WIDE_BREAKPOINTS_WHEN_ANY_LABEL_IS_LONGER_THAN_CHARS = 20;
const useWide = computed(():boolean=>
{    
    return canManageOrganizationCustomAttributes(props.organizationId) && Math.max(...[...displayCustomAttributes.value.map(a=>a.label.length),...displayInternalCustomAttributes.value.map(a=>a.label.length)]) > USE_WIDE_BREAKPOINTS_WHEN_ANY_LABEL_IS_LONGER_THAN_CHARS;
});

const parentOrganizationRoute = computed(() =>
{
    return {...Route.Organization, params:{ organizationId: organization.value?.parentId} };
});

const showAlternateIds = computed(()=>
{
     return !!(organization.value?.alternateIds?.length && canEditOrganizationAlternateId(props.organizationId))
});

let {
    loadAttributes,
    customAttributes,
    internalCustomAttributes
} = useOrganizationCustomAttributes();

const displayCustomAttributes = computed(()=>
{
    return Object.values(customAttributes).filter(attribute=>!!attribute.value);
});

const displayInternalCustomAttributes = computed(()=>
{
    return Object.values(internalCustomAttributes).filter(attribute=>!!attribute.value);
});    

const refreshOrganization = async () => 
{
    organization.value = await useOrganizationStore().getOrganization(props.organizationId);
    loadAttributes(organization.value);
    await useOrganizationStore().fetchOrganizationNameAndLogo([organization.value]);
    isReady.value = true;
}

const refreshTopCourses = async () =>
{
    if (canListCourses([props.organizationId]))
    {
        topCourses.value = (await useCourseStore().listCourses(topCoursesFilter))?.items;
    }
}

watch(()=>props.organizationId, refreshOrganization);
onMounted(async () => 
{
    await Promise.all([refreshOrganization(), refreshTopCourses()]);
})

const cityStateZip = computed(() =>
{
    const {city, state, zip} = organization.value?.contact || {};
    return `${city}, ${state} ${zip}`
})

const contactEmailMailtoAddress = computed(() =>
{
    return `mailto:${organization.value?.contact?.email}`
});

const hasCourses = computed(() =>
{
    return (topCourses.value || []).length > 0;
})

const coursesRoute = computed(() =>
{
    return {...Route.Courses, query: {organizationIds: props.organizationId}};
})

const instructorsRoute = computed(() =>
{
    return {...Route.OrganizationUsers, query: {roles: UserRole.ORGANIZATION_INSTRUCTOR, sortBy: OrganizationUserSortBy.Instructor, sortOrder: SortOrder.Asc}};
})

const tasRoute = computed(() =>
{
    return {...Route.OrganizationUsers, query: {roles: UserRole.ORGANIZATION_TA, sortBy: OrganizationUserSortBy.TA, sortOrder: SortOrder.Asc}};
})
const studentsRoute = computed(() =>
{
    return {...Route.OrganizationUsers, query: {roles: UserRole.ORGANIZATION_STUDENT, sortBy: OrganizationUserSortBy.Student, sortOrder: SortOrder.Asc}};
})

const onDeleteButtonClicked = async () =>
{
    await router.push(Route.Organizations);
}


</script>