<template>
    <cr-confirmation-dialog id="changePlanDialog" :title="t('BILLING_CHANGE_PLAN_TITLE')" @confirm="onDialogConfirmed" data-testing="change-plan-dialog">
        {{ t('BILLING_CHANGE_PLAN_MESSAGE' ) }}
    </cr-confirmation-dialog>
    <support-recipients-dialog v-model="showSupportMessageSentDialog" :recipients="recipients" data-testing="change-plan-support-recipients-dialog"/>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useSessionStore } from '../../../stores/sessionStore';
import { ISupportMessage } from '../../../interfaces/iSupportMessage';
import { SupportTopic } from '../../../interfaces/supportTopic';
import { useSupportStore } from '../../../stores/supportStore';
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';
import { usePlanStore } from '../../../stores/planStore';
import Config from '../../../config';
import { ref } from 'vue';
import { ISupportRecipient } from '../../../interfaces/iSupportRecipient';
import SupportRecipientsDialog from '../../support/dialogs/SupportRecipientsDialog.vue';
import { useSubscriptionStore } from '../../../stores/subscriptionStore';

const { t } = useI18n();

const props = defineProps<
{
    organization?: IOrganization;
}>();

const showSupportMessageSentDialog = ref(false)
const recipients = ref<ISupportRecipient[]>([])

const onDialogConfirmed = async () =>
{
    if (!props.organization) return;

    const subscribedPlansPromises = useSubscriptionStore().organizationSubscriptions(props.organization.id)?.map(async (sub) =>
    {
        const plan = await usePlanStore().getPlanByProductId(sub.productId);

        return {name: plan.name, expiration: sub.endDate};
    }) || [];

    const subscribedPlans = await Promise.all(subscribedPlansPromises);
    
    const identity = useSessionStore().identity;
    const supportRequest: Partial<ISupportMessage> = {}

    supportRequest.name = identity?.name;
    supportRequest.provider = identity?.provider;

    const body = t('BILLING_CHANGE_PLAN_SUPPORT_MESSAGE_BODY', {
        organizationName: props.organization?.name,
        organizationUrl: `${Config.PORTAL_UI_BASE_URL}/organizations/${props.organization?.id}`,
        plans: subscribedPlans.map(plan => `${plan.name}: ${plan.expiration}`).join('\n    ')
    });



    recipients.value = await useSupportStore().requestSupport({
        topic: SupportTopic.Organization,
        topicId: props.organization?.id,
        topicName: props.organization?.name,
        name: identity?.name,
        provider: identity?.provider,
        email: identity?.email,
        subject: t('BILLING_CHANGE_PLAN_SUPPORT_MESSAGE_SUBJECT'),
        body,
    });
    showSupportMessageSentDialog.value = true;
}
</script>
