<template>
    <main class="cr-height-100-vh">
        <template v-if="guacamoleAccessPointId">
            <non-persistent-exercise-header v-if="exercise && !exercise.isPersistent" v-model:exercise="exercise" :showClockOutOfSyncMessage="showClockOutOfSyncMessage" @session-ended="onSessionEnded"/>
            <div class="sidebar-iframe-container">
                <template v-if="labId">
                    <div class="sidebar" ref="sidebar" data-testing="cyber-range-sidebar">{{ labId }}</div>
                    <guacamole-separator v-model="sidebarWidth" v-model:is-resizing="isResizing" @done="onSeperatorResizeDone" class="separator"/>
                </template>
                <div :class="{ 'guacamole-iframe-container': !!labId }" style="flex-grow: 1;">
                    <guacamole-iframe :guacamoleAccessPointId="guacamoleAccessPointId" :disabled="isResizing || sessionDisabled"/>
                </div>
            </div>
        </template>
        <template v-if="connectionError">
            <cr-container>
                <cr-row align="center" justify="center" class="cr-height-100-vh">
                    <cr-loading class="cr-text-center"/>
                </cr-row>
            </cr-container>
            <cr-error-dialog v-model="isConnectionErrorDialogShowing" data-testing="connection-error-dialog">
                {{ connectionError }}
            </cr-error-dialog>
        </template>
        <errors-dialog v-else />
        <cr-announcer/>
    </main>
</template>

<style scoped>
main {
    --seperator-width: 3px;
    display: flex;
    flex-direction: column;
}
.sidebar-iframe-container {
    display: flex;
}

@media (min-width: 639px)
{
    main {
        overflow: hidden;
    }

    .sidebar-iframe-container {
        height: 100%;
    }

    .sidebar {
        border-right: 1px solid grey;
        overflow-y: auto;
        min-width: 320px;
        width: v-bind(sidebarWidth);
        max-width: calc(100vw - ( 320px + var(--seperator-width) ));
    }
    .separator {
        width: var(--seperator-width);
        background-color: grey;
        height: 100%;
        cursor: col-resize;
        z-index: 2;
    }
}

@media (max-width: 640px)
{
    .sidebar-iframe-container {
        flex-direction: column
    }
    .sidebar {
        border-bottom: 1px solid grey;
    }
    .separator {
        display: none;
    }

    .guacamole-iframe-container {
        height: 100vh;
    }
}
</style>

<script setup lang="ts">
import { IExercise } from "@cyber-range/cyber-range-api-exercise-client";
import { computed, ref, useTemplateRef, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import Route from '../../routers/route';
import { useExerciseStore } from '../../stores/exerciseStore';
import GuacamoleIframe from './GuacamoleIframe.vue'
import NonPersistentExerciseHeader from "./NonPersistentExerciseHeader.vue";
import ErrorsDialog from "../ErrorsDialog.vue";
import { useErrorStore } from "../../stores/errorStore";
import { usePageTitle } from "../../composables/usePageTitle";
import { useAnnouncementStore } from '@cyber-range/cyber-range-lib-ui';
import GuacamoleSeparator from './GuacamoleSeparator.vue'

const isResizing = ref(false);
const sidebarWidth = ref('320px');
let prevSidebarWidth = 320;
const sidebarRef = useTemplateRef('sidebar');
function onSeperatorResizeDone()
{
    const width = sidebarRef.value?.getBoundingClientRect().width;
    if (width && prevSidebarWidth !== width)
    {
        useAnnouncementStore().announce(`Sidebar width changed: ${width} pixels`);
        prevSidebarWidth = width;
    }
}

const exerciseStore = useExerciseStore();
const { t } = useI18n()
const router = useRouter();
const route = useRoute();

const props = defineProps<{
    exerciseId: string;
    accessPointId: string;
}>();

const exercise = ref<IExercise>();
const sessionDisabled = ref(false);
const guacamoleAccessPointId = ref('');
const showClockOutOfSyncMessage = ref(false);

const connectionError = ref('');

const onSessionEnded = () => sessionDisabled.value = true;

const pageTitle = computed(() =>
{
    const accessPointName = exercise.value?.accessPoints?.find(ap => ap.id === props.accessPointId)?.name
    return accessPointName || '';
});
usePageTitle(computed(() => pageTitle.value ? [{ text: pageTitle.value }]: []));

const labId = computed(() => route?.query?.labid || exercise.value?.recipeParameters?.find(rp => rp.id === 'labId')?.value);

watch(() => [props.exerciseId, props.accessPointId], async () =>
{
    let joinResponse: Awaited<ReturnType<typeof exerciseStore['join']>>;
    try
    {
        joinResponse = await exerciseStore.join(props.exerciseId, props.accessPointId);
    }
    catch
    {
        connectionError.value = t('RANGE_UNABLE_TO_JOIN_ERROR_MESSAGE');
        return;
    }

    guacamoleAccessPointId.value = joinResponse.guacamoleAccessPointId;

    const ourTime = new Date().getTime();
    const systemTime = new Date(joinResponse.currentTimestamp).getTime();
    const timeDelta = Math.abs(ourTime - systemTime)
    showClockOutOfSyncMessage.value = timeDelta > 60000;

    exercise.value = await exerciseStore.getOne(props.exerciseId);
}, { immediate: true});

const isConnectionErrorDialogShowing = computed({
    get: () => !!connectionError.value,
    async set(value: boolean)
    {
        if (value === true) return; // unsupported set - dialog only opens when there is an error message

        let exercise: IExercise|undefined;
        try
        {
            exercise = await exerciseStore.getOne(props.exerciseId);
        }
        catch {}

        useErrorStore().reset();

        const { courseId, groupId } = exercise || {};
        if (courseId && groupId)
        {
            router.push({ name: Route.ExerciseGroup.name, params: { courseId, exerciseGroupId: groupId }});
        }
        else if (courseId)
        {
            router.push({  name: Route.Course.name, params: { courseId } });
        }
        else
        {
            router.push(Route.Home);
        }
    }
});
</script>
