<template>
    <cr-confirmation-dialog :title="t('COURSE_APPROVED_DIALOG_TITLE')" :cancel="false" :confirm-text="t('COURSE_APPROVED_DIALOG_CONFIRM_TEXT')" data-testing="course-approved-dialog">
        {{ t('COURSE_APPROVED_DIALOG_MESSAGE' ) }}
    </cr-confirmation-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n();
</script>
