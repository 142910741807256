import { IApiPageResponse } from "@cyber-range/cyber-range-api-client";
import { DnsRecordName, ExerciseFilter, IExercise, IExerciseFilter, IExerciseGroupExercisesFilter, ISessionExpirationDetachOptions } from "@cyber-range/cyber-range-api-exercise-client";
import { defineStore } from "pinia";
import { useApiClientStore } from "./apiClientStore";

export const useExerciseStore = defineStore('exerciseStore', 
{
    state: () => 
    ({
        
    }),

    actions: 
    {
        async getExerciseGroupExercises(exerciseGroupId: string, filter?: Partial<IExerciseGroupExercisesFilter>, options?: {background:boolean}): Promise<IApiPageResponse<IExercise>>
        {
            const apiClientStore = useApiClientStore()
            const exerciseApiClient = options?.background ? apiClientStore.backgroundExerciseApiClient: apiClientStore.exerciseApiClient;

            return exerciseApiClient.getExerciseGroupExercises(exerciseGroupId, filter);
        },

        async getAllExerciseGroupExercises(exerciseGroupId: string, filter?: Partial<IExerciseGroupExercisesFilter>, options?: {background:boolean}): Promise<IExercise[]>
        {
            const apiClientStore = useApiClientStore()
            const exerciseApiClient = options?.background ? apiClientStore.backgroundExerciseApiClient: apiClientStore.exerciseApiClient;

            return exerciseApiClient.getAllExerciseGroupExercises(exerciseGroupId, filter);
        },

        async getAll(filter?: Partial<IExerciseFilter>, options?: {background:boolean}): Promise<IExercise[]>
        {
            const apiClientStore = useApiClientStore()
            const exerciseApiClient = options?.background ? apiClientStore.backgroundExerciseApiClient: apiClientStore.exerciseApiClient;

            return exerciseApiClient.getAll(new ExerciseFilter(filter));
        },

        async getOne(exerciseId: string, options?: {background:boolean}): Promise<IExercise>
        {
            const apiClientStore = useApiClientStore()
            const exerciseApiClient = options?.background ? apiClientStore.backgroundExerciseApiClient: apiClientStore.exerciseApiClient;

            return exerciseApiClient.getOne(exerciseId);
        },

        async start(id:string, options?: {background:boolean}): Promise<void>
        {
            const apiClientStore = useApiClientStore()
            const exerciseApiClient = options?.background ? apiClientStore.backgroundExerciseApiClient: apiClientStore.exerciseApiClient;

            await exerciseApiClient.start(id);
        },

        async stop(id:string, options?: {background:boolean}): Promise<void>
        {
            const apiClientStore = useApiClientStore()
            const exerciseApiClient = options?.background ? apiClientStore.backgroundExerciseApiClient: apiClientStore.exerciseApiClient;

            await exerciseApiClient.stop(id);
        },

        async reset(id:string, options?: {background:boolean}): Promise<void>
        {
            const apiClientStore = useApiClientStore()
            const exerciseApiClient = options?.background ? apiClientStore.backgroundExerciseApiClient: apiClientStore.exerciseApiClient;

            await exerciseApiClient.reset(id);
        },

        async snapshot(id:string, payload?:{name?: string, description?:string }, options?: {background:boolean}): Promise<string>
        {
            const apiClientStore = useApiClientStore()
            const exerciseApiClient = options?.background ? apiClientStore.backgroundExerciseApiClient: apiClientStore.exerciseApiClient;
            
            return await exerciseApiClient.snapshot(id, payload);
        },

        async join(exerciseId: string, accessPointId: string, options?: {background:boolean}): Promise<{
            guacamoleAccessPointId: string;
            sessionExpirationTimestamp: string;
            currentTimestamp: string;
        }>
        {
            const apiClientStore = useApiClientStore()
            const exerciseApiClient = options?.background ? apiClientStore.backgroundExerciseApiClient: apiClientStore.exerciseApiClient;

            return await exerciseApiClient.join(exerciseId, accessPointId);
        },

        extend(exerciseId: string, options?: {background:boolean})
        {
            const apiClientStore = useApiClientStore()
            const exerciseApiClient = options?.background ? apiClientStore.backgroundExerciseApiClient: apiClientStore.exerciseApiClient;

            return exerciseApiClient.extend(exerciseId);
        },

        detach(exerciseId: string, options?: Partial<ISessionExpirationDetachOptions>, requestOptions?: {background:boolean})
        {
            const apiClientStore = useApiClientStore()
            const exerciseApiClient = requestOptions?.background ? apiClientStore.backgroundExerciseApiClient: apiClientStore.exerciseApiClient;

            return exerciseApiClient.detach(exerciseId, options);
        },

        restart(id:string, options?: {background:boolean}): Promise<void>
        {
            const apiClientStore = useApiClientStore()
            const exerciseApiClient = options?.background ? apiClientStore.backgroundExerciseApiClient: apiClientStore.exerciseApiClient;

            return exerciseApiClient.restart(id);
        },
    }
})
