export default {
    en: {
        //Organizations listing
        ORGANIZATIONS_ORGANIZATION_NUMBER_OF_COURSES: "Courses",
        ORGANIZATIONS_ORGANIZATION_NUMBER_OF_USERS: "Users",
        ORGANIZATIONS_ORGANIZATION_NUMBER_OF_EXERCISES: "Exercises",
        ORGANIZATIONS_ORGANIZATIONS: "Organizations",
        ORGANIZATIONS_ORGANIZATIONS_NO_ITEMS: "No Organizations Found",
        ORGANIZATIONS_ORGANIZATION_CREATE_ORGANIZATION_BUTTON: "Create an Organization",

        //Organization page
        ORGANIZATION_DESCRIPTION: "Description",
        ORGANIZATION_CONTACT: "Contact Address",
        ORGANIZATION_CONTACT_PHONE_NUMBER: 'Phone Number',
        ORGANIZATION_CONTACT_EMAIL_ADDRESS: 'Email Address',
        ORGANIZATIONS_ORGANIZATION_EDIT_ORGANIZATION_BUTTON: "Edit Organization",
        ORGANIZATIONS_ORGANIZATION_ORGANIZATION_USERS_BUTTON: "Manage Users",
        ORGANIZATIONS_ORGANIZATION_BILLING_BUTTON: "Plans and Billing",
        ORGANIZATIONS_ORGANIZATION_ORGANIZATION_APPLICATIONS_BUTTON: "Connected Apps",
        ORGANIZATIONS_ORGANIZATION_MANAGE_BUSINESS_UNITS_BUTTON: "Manage Business Units",
        ORGANIZATIONS_ORGANIZATION_VIEW_LOGS_BUTTON: "View Logs",
        ORGANIZATIONS_ORGANIZATION_DELETE_ORGANIZATION_BUTTON: "Delete Organization",
        ORGANIZATIONS_ORGANIZATION_DASHBOARD_OUT_OF: "out of",
        ORGANIZATIONS_ORGANIZATION_DASHBOARD_COURSES: "Courses",
        ORGANIZATIONS_ORGANIZATION_ENVIRONMENTS: "Top Courses By Environment Count",
        ORGANIZATIONS_ORGANIZATION_DASHBOARD_DEPLOYED_ENVIRONMENTS: "Deployed Environments",
        ORGANIZATIONS_ORGANIZATION_DASHBOARD_COPIED_ENVIRONMENTS: "Copied Environments",
        ORGANIZATIONS_ORGANIZATION_DASHBOARD_TOTAL_ENROLLMENTS: "Total Enrollments",
        ORGANIZATIONS_ORGANIZATION_DASHBOARD_INSTRUCTORS: "Instructors",
        ORGANIZATIONS_ORGANIZATION_DASHBOARD_TAS: "TAs",
        ORGANIZATIONS_ORGANIZATION_DASHBOARD_STUDENTS: "Students",
        ORGANIZATIONS_ORGANIZATION_DASHBOARD_ADMIN_ACCOUNTS: "Admin Accounts",
        ORGANIZATIONS_ORGANIZATION_DASHBOARD_COURSES_AND_ENVIRONMENTS_SECTION: "Courses and Environments",
        ORGANIZATIONS_ORGANIZATION_DASHBOARD_ENROLLMENTS_SECTION: "Enrollments",
        ORGANIZATIONS_ORGANIZATION_DASHBOARD_ADMIN_ACCOUNTS_SECTION: "Admin Accounts",
        ORGANIZATIONS_ORGANIZATION_DELETE_ORGANIZATION_DIALOG_MESSAGE: "Are you sure you want to delete <b>{organizationName}</b>?",
        ORGANIZATIONS_ORGANIZATION_NO_DATA_AVAILABLE: "The data is not available at this time.",

        //Organization form
        ORGANIZATION_FORM_ORGANIZATION_SECTION: "Organization",
        ORGANIZATION_FORM_LOGO_SECTION: "Organization Logo",
        ORGANIZATION_FORM_LOGO_ALT_TEXT: "{organizationName} logo",
        ORGANIZATION_FORM_LOGO_DEFAULT_ALT_TEXT: "Organization logo",
        ORGANIZATION_FORM_ORGANIZATION_SECTION_ORGANIZATION_NAME: "Organization Name",
        ORGANIZATION_FORM_ORGANIZATION_SECTION_ORGANIZATION_DESCRIPTION: "Organization Description",
        ORGANIZATION_FORM_CONTACT_SECTION: "Organization Contact",
        ORGANIZATION_FORM_CONTACT_SECTION_FIRST_NAME: "First Name",
        ORGANIZATION_FORM_CONTACT_SECTION_LAST_NAME: "Last Name",
        ORGANIZATION_FORM_CONTACT_SECTION_EMAIL: "Email",
        ORGANIZATION_FORM_CONTACT_SECTION_PHONE: "Phone",
        ORGANIZATION_FORM_CONTACT_SECTION_ADDRESS_LINE_1: "Address",
        ORGANIZATION_FORM_CONTACT_SECTION_CITY: "City",
        ORGANIZATION_FORM_CONTACT_SECTION_STATE: "State",
        ORGANIZATION_FORM_CONTACT_SECTION_ZIP: "Zip",
        ORGANIZATION_FORM_ADVANCED_SECTION: "Advanced Settings",
        ORGANIZATION_FORM_AGREEMENT_SECTION_EXPIRATION_DATE: "Expiration Date",
        ORGANIZATION_FORM_ADVANCED_SECTION_ORGANIZATION_ALTERNATE_IDS: "Alternate Ids (comma separated)", 
        ORGANIZATION_FORM_ADVANCED_SECTION_ORGANIZATION_CODE: "Organization Code",
        ORGANIZATION_FORM_ADVANCED_SECTION_ORGANIZATION_PARENT: "Parent Organization",
        ORGANIZATION_FORM_LOGO_UPLOAD_FAILED_ERROR: "Organization logo upload failed. Please try again. Error: {errorMessage}",
        ORGANIZATION_FORM_LIMITS_SECTION_LABEL: 'Limits',
        ORGANIZATION_DIALOG_MAX_EXERCISE_GROUPS_PER_COURSE: 'Max Number of Exercise Groups per course',
        ORGANIZATION_DIALOG_MAX_INSTRUCTORS_PER_COURSE: 'Max Number of Instructors per Course',
        ORGANIZATION_DIALOG_MAX_TAS_PER_COURSE: 'Max Number of TAs per course',
        ORGANIZATION_DIALOG_MAX_TAS_PER_COURSE_ARIA: "Max Number of T.A.'s per Course",
        ORGANIZATION_DIALOG_MAX_STUDENTS_PER_COURSE: 'Max Number of Students per Course',
        ORGANIZATION_DIALOG_MAX_MAX_COPIED_ENVIRONMENTS_PER_USER: 'Max Number of Copied Environments per User',
        ORGANIZATION_DIALOG_MAX_ENROLLMENTS_PER_ORGANIZATION: 'Max Number of Enrollments per Organization',
        ORGANIZATION_FORM_LIMITS_HELP_TEXT: 'Learn about organization limits',

        // Organizations Advanced Settings Display 
        ORGANIZATION_ADVANCED_SETTINGS_SECTION_LABEL: "Advanced Settings",
        ORGANIZATION_ADVANCED_SETTINGS_PARENT_ORGANIZATION: "Parent Organization",
        ORGANIZATION_ADVANCED_SETTINGS_ALTERNATE_IDS: "Alternate Ids",
        ORGANIZATION_ADVANCED_SETTINGS_ORGANIZATION_CODE: "Organization Code",

        //Create Organization
        ORGANIZATIONS_CREATE_ORGANIZATION_PAGE_TITLE: "Create an Organization",

        //Edit Organization
        ORGANIZATIONS_EDIT_ORGANIZATION_PAGE_TITLE: "Edit an Organization",

        //Organization Users
        ORGANIZATION_USERS_PAGE_TITLE: 'Users',
        ORGANIZATION_USERS_NAME: 'Name',
        ORGANIZATION_USERS_EMAIL: 'Email',
        ORGANIZATION_USERS_LOGIN_WITH: 'Login With',
        ORGANIZATION_USERS_PENDING_SINCE: 'Pending Since',
        ORGANIZATION_USERS_BILLING_ADMIN: 'Billing Admin',
        ORGANIZATION_USERS_COURSE_ADMIN: 'Course Admin',
        ORGANIZATION_USERS_ORGANIZATION_ADMIN: 'Org Admin',
        ORGANIZATION_USERS_INSTRUCTOR: 'Instructor',
        ORGANIZATION_USERS_TA: 'TA',
        ORGANIZATION_USERS_STUDENT: 'Student',
        ORGANIZATION_USERS_LOGIN_PROVIDER: 'Login Provider',
        ORGANIZATION_USERS_BUSINESS_UNIT: 'Business Unit',
        ORGANIZATION_USERS_LAST_LOGIN: 'Last Login',
        ORGANIZATION_USERS_INVITE_USERS: 'Invite Users',
        ORGANIZATION_USERS_EXPORT_USERS: 'Export Users',
        ORGANIZATION_USERS_CHECK_ALT: 'True',
        ORGANIZATION_USERS_DELETE_USER_BUTTON: 'Delete',
        ORGANIZATION_USERS_APPROVE_INVITATION_BUTTON: 'Approve',
        ORGANIZATION_USERS_REJECT_INVITATION_BUTTON: 'Reject',
        ORGANIZATION_USERS_RESEND_INVITATION_BUTTON: 'Re-Invite',
        ORGANIZATION_USERS_DELETE_INVITATION_BUTTON: 'Delete',
        ORGANIZATION_USERS_EDIT_INVITATION_BUTTON: 'Edit Invitation',
        ORGANIZATION_USERS_VIEW_PROFILE_BUTTON: 'View Profile',
        ORGANIZATION_USERS_EDIT_USER_BUTTON: 'Edit User',
        ORGANIZATION_USERS_IMPERSONATE_BUTTON: 'Impersonate',
        ORGANIZATION_USERS_DELETE_USER_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{userName}</b>?',
        ORGANIZATION_USERS_RESEND_INVITATION_DIALOG_MESSAGE: 'Are you sure you want to re-invite <b>{invitationName}</b>?',
        ORGANIZATION_USERS_DELETE_INVITATION_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{invitationName}</b>?',
        ORGANIZATION_USERS_PARENT_FILTER_LABEL: 'Parent Organization',
        ORGANIZATION_USERS_LAST_LOGIN_FILTER_LABEL: 'Last Login Date',

        //Organization User Form
        ORGANIZATION_USER_FORM_INFO_SECTION: 'Information',
        ORGANIZATION_USER_FORM_NAME: 'Name',
        ORGANIZATION_USER_FORM_EMAIL: 'Email',
        ORGANIZATION_USER_FORM_ROLE_SECTION: 'Roles',
        ORGANIZATION_USERS_EDIT_USER_DIALOG_TITLE: 'Edit User',
        ORGANIZATION_USERS_EDIT_USER_DIALOG_CONFIRMATION_ANNOUNCEMENT: 'User updated successfully',
        ORGANIZATION_USERS_EDIT_INVITATION_DIALOG_TITLE: 'Edit Invitation',

        //Invite Organization User
        INVITE_ORGANIZATION_USER_PAGE_TITLE: 'Invite an Organization User',
        INVITE_ORGANIZATION_USER_FORM_ORGANIZATION_SECTION: 'Organization',
        INVITE_ORGANIZATION_USER_FORM_ORGANIZATION_SECTION_ORGANIZATION: 'Organization',
        INVITE_ORGANIZATION_USER_FORM_INFO_SECTION: 'User Information',
        INVITE_ORGANIZATION_USER_FORM_NAME: 'Name',
        INVITE_ORGANIZATION_USER_FORM_EMAIL: 'Email',
        INVITE_ORGANIZATION_USER_FORM_TITLE: 'Job Title',
        INVITE_ORGANIZATION_USER_FORM_BUSINESS_ROLES: 'Roles',
        INVITE_ORGANIZATION_USER_FORM_ROLE_ERROR: 'Select at least one role.',

        // Organization Applications
        ORGANIZATION_APPLICATIONS_PAGE_TITLE: 'Connected Applications',
        ORGANIZATION_APPLICATIONS_APPLICATION_ID: 'Application ID',
        ORGANIZATION_APPLICATIONS_NAME: 'Name',
        ORGANIZATION_APPLICATIONS_TYPE: 'Type',
        ORGANIZATION_APPLICATIONS_CLIENT_ID: 'Client ID',
        ORGANIZATION_APPLICATIONS_CREATED: 'Created',
        ORGANIZATION_APPLICATIONS_CREATE_APPLICATION_BUTTON: 'Create an Application',
        ORGANIZATION_APPLICATIONS_EDIT_APPLICATION_BUTTON: 'Edit',
        ORGANIZATION_APPLICATIONS_DELETE_APPLICATION_BUTTON: 'Delete',
        ORGANIZATION_APPLICATIONS_TYPE_LTI: 'LTI 1.3',
        ORGANIZATION_APPLICATIONS_DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{applicationName}</b>?',
        ORGANIZATION_APPLICATIONS_EDIT_APPLICATION_DIALOG_TITLE: 'Edit Application',
        ORGANIZATION_APPLICATIONS_FORM_TYPE: 'Application Type',
        ORGANIZATION_APPLICATIONS_LTI_FORM_NAME: 'Name',
        ORGANIZATION_APPLICATIONS_LTI_FORM_ISSUER: 'Issuer',
        ORGANIZATION_APPLICATIONS_LTI_FORM_CLIENT_ID: 'Client ID',
        ORGANIZATION_APPLICATIONS_LTI_FORM_KEY_SET_URL: 'Key Set Url',
        ORGANIZATION_APPLICATIONS_LTI_FORM_ACCESS_TOKEN_URL: 'Access Token Url',
        ORGANIZATION_APPLICATIONS_LTI_FORM_AUTHENTICATION_URL: 'Authentication Url',
        ORGANIZATION_APPLICATIONS_LTI_FORM_DEPLOYMENT_IDS: 'Deployment IDs',
        ORGANIZATION_APPLICATIONS_LTI_FORM_DEPLOYMENT_IDS_HINT: 'Deployment IDs should be formatted as a comma separated list',
        CREATE_ORGANIZATION_APPLICATION_PAGE_TITLE: 'Create Application',
    }
};