import { ExerciseStatus, IExercise } from "@cyber-range/cyber-range-api-exercise-client";
import { Products } from "@cyber-range/cyber-range-api-subscription-client";
import { Ref, computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import Route from "../routers/route";
import { useAuthenticationStore } from "../stores/authenticationStore";
import { useAuthorizationStore } from "../stores/authorizationStore";
import { useExerciseStore } from "../stores/exerciseStore";
import { useSubscriptionStore } from "../stores/subscriptionStore";
import { useExerciseDurationWarnings } from "./useExerciseDurationWarnings";
import { TrackJS } from 'trackjs';
import { useExerciseGroupStore } from "../stores/exerciseGroupStore";

export function useExerciseControls(exercise: Ref<IExercise>)
{
    const exerciseStore = useExerciseStore();
    const authorizationStore = useAuthorizationStore();
    const subscriptionStore = useSubscriptionStore();
    const router = useRouter();

    let didAttachedToStoppingExercise = false;
    const isLoading = ref<boolean>();
    watch(() => exercise.value.status, async (newStatus, oldStatus) =>
    {
        // When status is stub and isLoading is true, this assumes start was pressed since that is the only available action for a stub exercise
        if (isLoading.value && oldStatus === ExerciseStatus.STUB && (newStatus === ExerciseStatus.PENDING_STOPPING || newStatus === ExerciseStatus.STOPPED))
        {
            didAttachedToStoppingExercise = true
        }

        if (didAttachedToStoppingExercise && newStatus === ExerciseStatus.STOPPED)
        {
            try
            {
                await exerciseStore.start(exercise.value.id, { background: true });
            }
            catch {}
            didAttachedToStoppingExercise = false;
        }
        else if (!didAttachedToStoppingExercise && newStatus !== oldStatus)
        {
            isLoading.value = false;
        }
    });

    // Start
    const canStartExercise = computed(() => (
        !isLoading.value
        && (exercise.value.status === ExerciseStatus.STOPPED || exercise.value.status === ExerciseStatus.STUB)
        && authorizationStore.canStartExercise(exercise.value.courseId, exercise.value.organizationId)
        && !(useExerciseDurationWarnings(computed(()=> exercise.value)).isDepleted.value)
    ));
    async function startExercise()
    {
        isLoading.value = true;
        await exerciseStore.start(exercise.value.id, { background: true });
    };

    // Join
    const canJoinExercise = computed(() => (
        !isLoading.value
        && exercise.value?.status === ExerciseStatus.READY
        && authorizationStore.canJoinExercise(exercise.value.courseId, exercise.value.organizationId)
        && !(useExerciseDurationWarnings(computed(()=> exercise.value)).isDepleted.value)
    ));
    const joinExercisePath = (accessPointId?: string) => accessPointId ? router.resolve({ name: Route.ExerciseAccessPoint.name, params: { exerciseId: exercise.value.id, accessPointId } }).href : '';
    async function joinExercise(accessPointId?: string)
    {
        if(!accessPointId)
        {
            const error = new Error('This exercise does not have an access point id');
            TrackJS.track(error);
            console.error(error.message);
            return;
        }

        isLoading.value = true;
        try
        {
            const path = joinExercisePath(accessPointId);
            const url = new URL(path, window.location.origin);
            url.searchParams.set('s', await useAuthenticationStore().getSsoToken())
            window.open(url.href, '_blank');
        }
        finally
        {
            isLoading.value = false;
        }
    }

    // Stop
    const canStopExercise = computed(() => (
        !isLoading.value
        && exercise.value?.status === ExerciseStatus.READY
        && authorizationStore.canStopExercise(exercise.value.courseId, exercise.value.organizationId)
    ));
    async function stopExercise()
    {
        isLoading.value = true;
        await exerciseStore.stop(exercise.value.id, { background: true });
    };

    // Reset
    const canResetExercise = computed(() => (
        !isLoading.value
        && (exercise.value?.status === ExerciseStatus.STOPPED || exercise.value?.status === ExerciseStatus.ERROR)
        && authorizationStore.canResetExercise(exercise.value.courseId, exercise.value.organizationId)
        && !(useExerciseDurationWarnings(computed(()=> exercise.value)).isDepleted.value)
    ));
    async function resetExercise()
    {
        isLoading.value = true;
        await exerciseStore.reset(exercise.value.id, { background: true });
    };

    // Restart
    const canRestartExercise = computed(() => (
        !isLoading.value
        && exercise.value?.status === ExerciseStatus.READY
        && authorizationStore.canRestartExercise(exercise.value.courseId, exercise.value.organizationId)
        && !(useExerciseDurationWarnings(computed(()=> exercise.value)).isDepleted.value)
    ));
    async function restartExercise()
    {
        isLoading.value = true;
        await exerciseStore.restart(exercise.value.id);
    }

    // Copy
    const exerciseCopyInitiated = ref<boolean>(false);
    subscriptionStore.fetchOrganizationSubscribedProducts(exercise.value.organizationId);

    const canCopyExercise = computed(() => (
        !isLoading.value
        && exercise.value?.status === ExerciseStatus.STOPPED
        && authorizationStore.canSnapshotExercise(exercise.value.courseId, exercise.value.organizationId, exercise.value.id)
        && subscriptionStore.isSubscribedTo(exercise.value.organizationId, Products.ExerciseEnvironmentSnapshotFeature)
    ));
    async function copyExercise(name?: string)
    {
        isLoading.value = true;
        exerciseCopyInitiated.value = true;
        const group = await useExerciseGroupStore().getCourseExerciseGroup(exercise.value.courseId, exercise.value.groupId);
        await exerciseStore.snapshot(exercise.value.id, { name, description: group.description }, { background: true });
    }

    return {
        canStartExercise,
        startExercise,
        canJoinExercise,
        joinExercise,
        canStopExercise,
        stopExercise,
        canResetExercise,
        resetExercise,
        canRestartExercise,
        restartExercise,
        canCopyExercise,
        copyExercise,
        isLoading,
        exerciseCopyInitiated
    }
}
