import { ExerciseGroupStatus, IExerciseGroup } from '@cyber-range/cyber-range-api-user-client';
import { Ref, computed, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useExerciseGroupStore } from '../stores/exerciseGroupStore';
import { useTimerStore } from '../stores/timerStore';

export function useCourseExerciseGroupsList(courseId: Ref<string>)
{
    const timerStore = useTimerStore();

    const exerciseGroups = ref<IExerciseGroup[]>([]);
    const exerciseGroupStore = useExerciseGroupStore();
    const isReady = ref(false);

    const fetchExerciseGroups = async () =>
    {
        exerciseGroups.value = await exerciseGroupStore.listAllInCourse(courseId.value, { background: isReady.value });
        isReady.value = true;
    }
    watch(() => courseId.value, async () =>
    {
        if (!courseId.value)
        {
            return;
        }

        fetchExerciseGroups()
    }, { immediate: true });

    const hasPendingExerciseGroup = computed<boolean>(() => !!exerciseGroups.value?.find(isPendingExerciseGroup));
    let pollingTimerId = ''
    watch(hasPendingExerciseGroup, () =>
    {
        if (hasPendingExerciseGroup.value && !pollingTimerId)
        {
            pollingTimerId = timerStore.schedule(fetchExerciseGroups, undefined, 5000, true);
        }
        else if (!hasPendingExerciseGroup.value && !!pollingTimerId)
        {
            timerStore.unschedule(pollingTimerId);
            pollingTimerId = '';
        }
    }, { immediate: true });

    function isPendingExerciseGroup(exerciseGroup: IExerciseGroup): boolean
    {
        return exerciseGroup.status !== ExerciseGroupStatus.READY && exerciseGroup.status !== ExerciseGroupStatus.ERROR;
    }

    onUnmounted(() => timerStore.unschedule(pollingTimerId));

    return { exerciseGroups, isReady };
}