<template>
    <cr-confirmation-dialog v-if="showSurvey" v-model="surveyStore.showDialog" :title="t('INSTRUCTOR_SURVEY_DIALOG_TITLE')" :confirm-text="t('INSTRUCTOR_SURVEY_LINK_TEXT')" :cancel-text="t('INSTRUCTOR_SURVEY_REMIND_ME_LATER')" @confirm="onConfirmClicked" @cancel="onCancelClicked">
        <p v-html="t('INSTRUCTOR_SURVEY_DIALOG_TEXT')" />
    </cr-confirmation-dialog>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useInstructorSurveyStore } from '../../stores/instructorSurveyStore';
const { t } = useI18n()

const surveyStore = useInstructorSurveyStore();
const showSurvey = computed(() => surveyStore.shouldShowSurvey && !surveyStore.hasTakenSurvey)
function onConfirmClicked()
{
    surveyStore.dismissPermanently();
    window.open(surveyStore.url, '_blank');
}
function onCancelClicked()
{
    surveyStore.showDialog = false;
    surveyStore.trackRemindMeLater();
}

watch(showSurvey, () =>
{
    if (showSurvey.value && surveyStore.showDialog)
    {
        surveyStore.trackDialogShow();
    }
}, { immediate: true });
</script>
