<template>
    <cr-confirmation-dialog v-model="showContinueDialog" @confirm="onContinueConfirmed" :cancel="false" data-testing="agreements-continue-dialog" :title="t('AGREEMENTS_TITLE')" :confirmText="t('AGREEMENTS_CONTINUE_DIALOG_CONFIRM_TEXT')" :persistent="true">   
        {{ t('AGREEMENTS_CONTINUE_DIALOG_TEXT') }}
    </cr-confirmation-dialog>

    <cr-confirmation-dialog class="cr-m0" v-for="currentAgreement in pendingAgreements" v-model="pendingAgreementDialogController[currentAgreement.id]" @confirm="onCurrentAgreementConfirmed" @cancel="onCurrentAgreementCancelled" :data-testing="createDialogSelectorByAgreementId(currentAgreement.id)" :confirmationMessage="t('AGREEMENTS_I_AGREE_TEXT')" :checkbox="true" :title="currentAgreement?.name" :confirmText="t('AGREEMENTS_CURRENT_DIALOG_CONFIRM_TEXT')" :cancelText="t('AGREEMENTS_CURRENT_DIALOG_CANCEL_TEXT')" :confirm-background-color="confirmBackgroundColor" :disabled="isLoading" :persistent="true">
        <div class="scrollable-area">
            <p v-for="paragraph in toParagraphsArray(currentAgreement?.content)">{{paragraph.trim()}}</p>
        </div>       
    </cr-confirmation-dialog>

    <cr-confirmation-dialog v-model="showErrorDialog" @confirm="onAgreementErrorConfirmed" :title="t('AGREEMENTS_TITLE')" data-testing="agreement-declined-dialog" :cancel="false" :confirmText="t('AGREEMENTS_LOGOUT_TEXT')" :persistent="true">
        {{ t('AGREEMENTS_ERROR_MESSAGE') }}
    </cr-confirmation-dialog>
</template>

<style scoped>
.scrollable-area {
    max-height: 50vh;
    overflow-y: auto;
}
</style>

<script setup lang="ts">
import { IAgreement } from '@cyber-range/cyber-range-api-agreement-client';
import { useAgreementStore } from '../../stores/agreementStore';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useI18n } from 'vue-i18n';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';

const { isLoading } = storeToRefs(useApiClientStore());

const { t } = useI18n();

const props = defineProps<{
    pendingAgreements:IAgreement[],
}>();

const emit = defineEmits<{
    (name: 'confirm'): void,
    (name: 'cancel'): void
}>();

const toParagraphsArray = (agreementContent:string = ""):string[]=>
{
    agreementContent = agreementContent.replace(/\t/g, "");
    return agreementContent.split(/(?:\r\n|\r|\n)/g);    
};

const confirmBackgroundColor = 'var(--cr-confirmation-dialog-confirm-background-color)';

const createDialogSelectorByAgreementId = (agreementId?:string)=>
{
    return `agreement-dialog-for-${agreementId}`;
}

// for tracking which agreement dialog is open
const agreementIds = ref<string[]>([]); 
const pendingAgreementDialogController = ref<Record<string, boolean>>({}); 

const showContinueDialog = ref<boolean>(false); 
const showErrorDialog = ref<boolean>(false);

const onContinueConfirmed = async()=>
{
    showContinueDialog.value = false;
    
    pendingAgreementDialogController.value[agreementIds.value[0]] = true;
}

const onCurrentAgreementConfirmed = async()=>
{
    // get Id for the agreement that was just accepted, and shift the array so the next agrement id is at index 0;
    const acceptedAgreementId:string = agreementIds.value.shift() as string; 
    await (useAgreementStore().accept(acceptedAgreementId));
    
    //close the dialog for the agreement that was just accepted
    pendingAgreementDialogController.value[acceptedAgreementId] = false;
    
    // open the dialog for the next agreement if it exists
    const nextAgreementId:string = agreementIds.value[0] as string;
    if(nextAgreementId)
    {
        pendingAgreementDialogController.value[nextAgreementId] = true;
    }
    emit('confirm');
}

const onCurrentAgreementCancelled = async()=>
{
    // close the declined agreement dialog 
    const declinedAgreementId:string = agreementIds.value.shift() as string; 
    pendingAgreementDialogController.value[declinedAgreementId] = false;

    showErrorDialog.value = true;
}

const onAgreementErrorConfirmed = async()=>
{   
    emit('cancel');
}

const onKeydown = (event:KeyboardEvent)=>
{
    if(event.key === 'Escape')
    {
        const isShowingAPendingAgreement = Object.values(pendingAgreementDialogController.value || {}).some(v=>!!v);
        if(isShowingAPendingAgreement)
        {
            onCurrentAgreementCancelled()
        }
        else if(!!showErrorDialog.value)
        {
            onAgreementErrorConfirmed()
        }
        else if(!!showContinueDialog.value)
        {            
            showContinueDialog.value = false;
            showErrorDialog.value = true;
        }
    }
}

onMounted(async () => 
{
    //support esc key to trigger onCurrentAgreementCancelled
    window.addEventListener('keydown', onKeydown);
    
    // create the table used to control which of the pending agreement dialogs to show.
    pendingAgreementDialogController.value = {};
    agreementIds.value = [];
    for(let agreement of props.pendingAgreements)
    {
        pendingAgreementDialogController.value[agreement.id] = false;
        agreementIds.value.push(agreement.id);
    }
    showContinueDialog.value = true;
});

onBeforeUnmount(()=>
{
    window.removeEventListener('keydown', onKeydown);
})
</script>